import React, { useState, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Row, Col, Input, Form, FormGroup } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { Query } from 'react-apollo';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { dateFormatter, priceFormatter } from '../../utils/Formatters';
import Loading from '../../components/Loading/Loading';
import { getPayments } from '../../queries/Queries';

/**
 * Builds the GraphQL `where` clause for filtering payments.
 * @param {string} customerId - The customer ID.
 * @param {Object} filters - The filters object containing account number, start date, and end date.
 * @returns {string} The constructed `where` clause.
 */
const buildWhereClause = (customerId, filters) => {
  let whereClause = `account.customer.id == ${customerId} and account.number==*${filters.accountNumber}*`;

  if (filters.startDate && filters.endDate) {
    whereClause += ` and paymentDate >= ${filters.startDate.toISOString()} and paymentDate <= ${filters.endDate.toISOString()}`;
  }

  return whereClause;
};

const PaymentsView = ({ data, match }) => {
  const [pagination, setPagination] = useState({ page: 1, size: 10 });
  const [sort, setSort] = useState({ field: 'paymentDate', order: 'desc' });
  const [filters, setFilters] = useState({
    startDate: moment().subtract(1, 'year').startOf('day'),
    endDate: moment().startOf('day'),
    accountNumber: '',
  });
  const [accountNumberInput, setAccountNumberInput] = useState(''); // Separate input state for account number
  const [focusedInput, setFocusedInput] = useState(null); // For DateRangePicker focus state

  // Debounce function to delay filter updates
  const debouncedUpdateFilters = useRef(
    _.debounce((updatedFilters) => {
      setFilters(updatedFilters);
    }, 500)
  ).current;

  // Table columns definition
  const columns = [
    {
      dataField: 'accountNumber',
      text: 'Account Number',
      sort: true,
    },
    {
      dataField: 'paymentDate',
      text: 'Date',
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: priceFormatter,
      sort: true,
    },
  ];

  /**
   * Handles table changes such as pagination and sorting.
   * @param {string} type - The type of table change.
   * @param {Object} params - The parameters for the table change.
   */
  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    setPagination({ page, size: sizePerPage });
    setSort({ field: sortField, order: sortOrder });
  };

  // Determine customer ID from data prop for single customer role or URL params for multiple
  const customerId = data ? data.Customer.content[0].id : match.params.param;

  return (
    <div className='manage-users'>
      <Row className='subheader'>
        <Col className='col-8'>
          <h3>Payments</h3>
        </Col>
      </Row>
      <div className='page-cnt'>
        <Form>
          <Row className='mb-3'>
            <Col md='8'>
              <FormGroup>
                <Input
                  type='text'
                  name='accountNumber'
                  id='accountNumber'
                  value={accountNumberInput}
                  onChange={(e) => {
                    setAccountNumberInput(e.target.value);
                    debouncedUpdateFilters({ ...filters, accountNumber: e.target.value || '' });
                  }}
                  placeholder='Search by Account Number'
                />
              </FormGroup>
            </Col>
            <Col md='4'>
              <FormGroup>
                <DateRangePicker
                  small
                  showClearDates={true}
                  startDate={filters.startDate}
                  startDateId='startDate'
                  endDate={filters.endDate}
                  endDateId='endDate'
                  onDatesChange={({ startDate, endDate }) => {
                    if (!endDate || startDate.isSameOrBefore(endDate)) {
                      setFilters({ ...filters, startDate, endDate });
                    }
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(focused) => setFocusedInput(focused)}
                  isOutsideRange={() => false}
                  displayFormat={'DD/MM/YYYY'}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col sm='12'>
            <Query
              query={getPayments}
              variables={{
                page: pagination.page - 1,
                size: pagination.size,
                sort: {
                  orders: [
                    {
                      property: sort.field,
                      direction: sort.order,
                    },
                  ],
                },
                where: buildWhereClause(customerId, filters),
              }}
            >
              {({ loading, error, data }) => {
                if (loading) return <Loading />;
                if (error) return <p>Error: {error.message}</p>;
                if (!data || !Array.isArray(data.Payment.content)) return <p>No Data</p>;

                const payments = data.Payment.content.map((payment) => ({
                  id: payment.id,
                  paymentDate: payment.paymentDate,
                  accountNumber: payment.account.number,
                  amount: payment.amount,
                }));

                const total = data.Payment.totalElements;

                return (
                  <BootstrapTable
                    keyField={'id'}
                    classes={'text-center align-middle'}
                    headerClasses='th-expand'
                    wrapperClasses='table-responsive'
                    data={payments}
                    columns={columns}
                    bordered={false}
                    remote={{
                      filter: true,
                      pagination: true,
                      sort: true,
                    }}
                    sort={{
                      dataField: sort.field,
                      order: sort.order,
                    }}
                    pagination={paginationFactory({
                      page: pagination.page,
                      sizePerPage: pagination.size,
                      totalSize: total,
                      sizePerPageList: [
                        { text: '5', value: 5 },
                        { text: '10', value: 10 },
                        { text: '20', value: 20 },
                      ],
                    })}
                    onTableChange={handleTableChange}
                  />
                );
              }}
            </Query>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PaymentsView;
